// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
//  const BASE_URL = "http://127.0.0.1:5000/api/";
// const BASE_URL = "http://162.19.139.70:5000/api/";
// const BASE_URL = "http://192.168.11.13:5000/api/";
// const BASE_URL = "http://127.0.0.1:5000/api/";
//LIVE API URL
// const BASE_URL = "http://162.19.139.70:5000/api/";
//const BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
// export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://mlmadmin.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://127.0.0.1:5000/";
// const BASE = "http://162.19.139.70:5000";
// const BASE = "http://beta.infinitemlmsoftware.com:5000/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
//const DEFAULT_KEY ='14869ac2-d19b-497b-b0bb-4845393e11f9'
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
//'387245a5a919ea2071cc406b10b89d4685e5cc8e'

// live https://admin.acefreeway.co/api
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://admin.acefreeway.co/api/web/";
const IMG_URL = "https://admin.acefreeway.co/uploads/images/";
const BASE = "https://admin.acefreeway.co/";
const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'

//demo
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://162.19.146.135:1108/api/web/";
// const IMG_URL = "http://mlmadmin.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://162.19.146.135:1108/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'

export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY,
   LEAD_URL
}

