import React from "react";
import { Container } from "react-bootstrap";
import style from "../dashboard.module.scss";
import Profiles from "./profiles";
import Tile from "./Tile";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function MainContent(props) {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <Container fluid className={`p-3`} style={props?.style}>
      {/* <NewProfile profile={props.profile}/> */}
      <div className={style.left_navigation_fold}>
        <div className={style.main_contant_sec}>
          <section className={style.contant_sec}>
            <div className={style.cntr_main_cnt_sc}>
              <div style={{display : "flex" , justifyContent : "center"}}className={style.dashboard_main_cnt}>
                <Profiles
                {...props}
                uploadImgae={props.uploadImgae}
                />
                {/* <div className={style.alert_content}><span>This is a free user account inorder to get benifits and your placements you have to make a payment of 59. To make the payment please <Link
                  to="/payment"
                  className={`${
                    currentTheme == "theme-dark"
                      ? style.dark_mode_view_prfl_btn
                      : style.view_prfl_btn
                  }`}
                >
                 ClickHere
                </Link></span></div> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </Container>
  );
}

export default MainContent;
