import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { routes, publicRoute, replicaRoute } from "./routes";
import { MainLayout, AuthLayout } from "../layout";
import AppInfoservice from "../../service/common/Appinfo";
import UserStore from "../../store/UserStore";
import { useDispatch, useSelector } from "react-redux";
import { islogged } from "../../store/action";
import Spinner from "../shared/Spinner";
import NotFount from "../components/404/NotFount";

import { useParams } from "react-router-dom";
import JSEncrypt from "jsencrypt";
import ResetPassword from "../view/auth/login/ResetPassword";
import ResetTranPassword from "../view/auth/login/ResetTransactionPassword";
import { logout } from "../../store/action/";

import UnApprovedDashboard from '../view/dashboard/UnApproved/unhome'
import Timeout from "../view/autologout/TImeout";
import ForgotPassword from "../view/auth/login/ForgotPassword";
import TestTree from "../view/mobile_tree/testtree";
import SponsorTree from '../view/mobile_tree/sponsorTree';
import TreeView from '../view/mobile_tree/TreeView';

// import RegisterVerification from "../view/auth/login/RegisterVerification";

const RouteMap = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const logged = useSelector((state) => state.isLogged);
  const [tokens, setToken] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("string");
  // let key = urlParams.get("db_prefix");
  let title = urlParams.get('title');
  let string = urlParams.get('string');
  let prefix = urlParams.get('prefix');

  const ckecking = localStorage.getItem("approved")


  useEffect(() => {
    const key = localStorage.getItem('apiKey')
    const fetchToken = async () => {
      // localStorage.setItem("apiKey", key);
      const service = new AppInfoservice();
      const result = await service.validateString(code, key);
      if (result.status) {
        setToken(result.data.access_token);
        const result1 = await service.check_token({ token: result.data.access_token, prefix: `${prefix}_` });
        if (result1.status) {
          setLoader(false)
          localStorage.setItem(
            "login",
            JSON.stringify({
              token: result.data.access_token,
            })
          );
          UserStore.key = result.data.access_token;
          UserStore.isLoggedIn = true;
          dispatch(islogged());
          history.push("/dashboard");
        }
        else {
          // history.push("/logout");
        }
      }
      // history.push("/logout");
    }

    if (code && key) {
      fetchToken();
    }
  }, []);


  useEffect(() => {
    const fetchTokenone = async () => {
      localStorage.setItem("apiKey", prefix);
      const service = new AppInfoservice();
      const result1 = await service.check_token({ token: string });
      if (result1.status) {
        setLoader(false)
        localStorage.setItem(
          "login",
          JSON.stringify({
            token: string,
          })
        );
        UserStore.key = string;
        UserStore.isLoggedIn = true;
        localStorage.setItem("approved", result1.data.approved)
        dispatch(islogged());
        history.push(`/${title}`);
      }
      else{
        setLoader(false)
        history.push(`/login`);
      }
      // const result = await service.validateTreeString(title,string,prefix);
      // if (result.status) {
      //   setToken(result.data.access_token);

      //   else{
      //     history.push(`/login`);
      //   }
      // }
    }
    if (title && string) {
      fetchTokenone()
    }
  }, []);






  useEffect(() => {
    if (!title && !string) {
      const service = new AppInfoservice();
      let token = {
        token: UserStore.key,
      };
      service.check_token(token).then((res) => {
        setLoader(false);
        if (res.status) {
          dispatch(islogged());
         
          UserStore.isLoggedIn = true;
          if (window.location.pathname) {
            history.push(
              window.location.pathname === "/login"
                ? "/dashboard"
                : window.location.pathname
            );
          } else {
            history.push("/dashboard");
          }
        } else {
          if (
            window.location.pathname.includes("/reset_password") ||
            window.location.pathname.includes("/reset_tran_password") ||
            window.location.pathname.includes("/replica") ||
            window.location.pathname.includes("/privacy-policy") ||
            window.location.pathname.includes("/terms-conditions") ||
            window.location.pathname.includes("/replica_register") ||
            window.location.pathname.includes("/lcp") ||
            window.location.pathname.includes("/login") ||
            window.location.pathname.includes("/forgotPassword") ||
            window.location.pathname.includes("/genealogyTree") ||
            window.location.pathname.includes("/mobileGenealogy")  ||
            window.location.pathname.includes("/mobileSponser") ||
            window.location.pathname.includes("/mobileTreeView")||
            window.location.pathname.includes("/confirm_email")
          ) {
            history.push(window.location.pathname)
            // history.push(window.location.pathname);
          } else {
            dispatch(logout());
            localStorage.removeItem("login");
            localStorage.removeItem("apiKey");
            UserStore.key = "";
            history.push("/login");
          }
        }
      });
    }
  }, [history, dispatch]);

  // useEffect(() => {
  //   history.listen((location) => {
  //     console.log("======called");
  //     if (history.action === "PUSH") {
  //       console.log(location);
  //     }
  //   });
  //   console.log("========rendr when back button clicks");
  //   if (!code && !key) {
  //     const service = new AppInfoservice();
  //     let token = {
  //       token: UserStore.key,
  //     };
  //     service.check_token(token).then((res) => {
  //       setLoader(false);
  //       if (res.status) {
  //         dispatch(islogged());
  //         if (window.location.pathname) {
  //           history.push(
  //             window.location.pathname === "/login"
  //               ? "/dashboard"
  //               : window.location.pathname
  //           );
  //         } else {
  //           history.push("/dashboard");
  //         }
  //       } else {
  //         if (window.location.pathname.includes("/logout")) {
  //           history.push("/login");
  //         }
  //         if (
  //           window.location.pathname.includes("/reset_password") ||
  //           window.location.pathname.includes("/reset_tran_password") ||
  //           window.location.pathname.includes("/replica") ||
  //           window.location.pathname.includes("/privacy-policy") ||
  //           window.location.pathname.includes("/terms-conditions") ||
  //           window.location.pathname.includes("/replica_register")
  //         ) {
  //           history.push(window.location.pathname);
  //         } else {
  //           dispatch(logout());
  //           localStorage.removeItem('login');
  //           localStorage.removeItem('apiKey');
  //           UserStore.key = ''
  //           history.push('/login');
  //         }
  //       }
  //     });
  //   }
  // }, [history, dispatch]);

  if (loader) {
    return <Spinner />;
  }
  return (
    <Switch>
      <Route path="/reset_password/:resetkey" component={ResetPassword} />
      {/* <Route path="/confirm_email/:username/:adminname" component={RegisterVerification} /> */}
      <Route path="/reset_tran_password/:resetkey" component={ResetTranPassword} />
      <Route path="/unapproved/dashboard" component={UnApprovedDashboard} />
      <Route path="/timeout" component={Timeout} />
      <Route path="/mobileGenealogy" component={TestTree} />
      <Route path="/mobileSponser" component={SponsorTree} />
      <Route path="/mobileTreeView" component={TreeView} />
      {replicaRoute.map((component, index) => (
        <Route path={component.path} exact={component.exact} key={index}>
          <component.component />
        </Route>
      ))}

      {/* <Route
        path="/replica/:user_name:/admin"
        component={Replica}
      />
      <Route
        path="/privacy-policy/:user_name"
        component={privacy}

      />
      <Route
        path="/terms-conditions/:user_name"
        component={terms}

      /> */}
      {logged ? (
        <MainLayout>
          <Switch>
            {routes.map((component, index) => (
              <Route path={component.path} exact={component.exact} key={index}>
                <component.component />
              </Route>
            ))}
            <Route path="/404" component={NotFount} />
            <Redirect to="/404" />
          </Switch>
        </MainLayout>
      ) : (
        <AuthLayout>
          {publicRoute.map((component, index) => (
            <Route path={component.path} exact={component.exact} key={index}>
              <component.component />
            </Route>
          ))}
        </AuthLayout>
      )}
    </Switch>
  );
};

export default RouteMap;
