import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
// import sidebaritems from '../../data/sidebar-nav-items';
import { changeMenu } from "../../store/action";
import { bindActionCreators } from "redux";
import MenuItemLink from "./MenuItemsLink";
import style from "./commonstyle.module.scss";
import { useSelector } from "react-redux";
import navItems from "../../data/sidebar-nav-items";
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined'
import LiquorOutlinedIcon from '@mui/icons-material/LiquorOutlined';
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import AppInfoservice from "./../../service/common/Appinfo";


function mapStateToProps(state) {
  const { Menu } = state;
  return { sideMenu: Menu };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      changeMenu: bindActionCreators(changeMenu, dispatch),
    },
  };
}

let dashboard = {
  icon: "DesktopWindowsIcon",
  substatus: false,
  title: "Dashboard",
  to: true ? "/dashboard" : "/unapproved/dashboard",
};

const Sidebar = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [state, setState] = useState({});

  let items = props.sideMenu.Menu;

  function toggleMenuState(menuState) {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 1) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        if (i !== "menuitems") {
          setState({ [i]: false });
        }
      });
      setState({ [menuState]: true });
    }
  }

  useEffect(() => {
    localStorage.setItem("path", props.location.pathname);
  }, [props.location.pathname,state.url]);

  // componentDidUpdate(prevProps) {
  //   localStorage.setItem('path',this.props.location.pathname)
  //   if (this.props.location !== prevProps.location) {
  //     this.props.actions.changeMenu(this.props.location.pathname)
  //     this.onRouteChanged();
  //   }
  // }

  function onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    if (state.isSubmenu) {
      return true;
    }
    Object.keys(state).forEach((i) => {
      if (i !== "menuitems") setState({ [i]: false });
    });
    // const dropdownPaths = [
    //   {path:'/apps', state: 'appsMenuOpen'},
    //   {path:'/basic-ui', state: 'basicUiMenuOpen'},
    //   {path:'/form-elements', state: 'formElementsMenuOpen'},
    //   {path:'/tables', state: 'tablesMenuOpen'},
    //   {path:'/icons', state: 'iconsMenuOpen'},
    //   {path:'/charts', state: 'chartsMenuOpen'},
    //   {path:'/user-pages', state: 'userPagesMenuOpen'},
    //   {path:'/error-pages', state: 'errorPagesMenuOpen'},
    // ];
    const dropdownPaths = props.sideMenu.Menu.filter((menu) => {
      if (menu.subMenuStatus) {
        return {
          path: menu.to,
          state: menu.title,
        };
      }
      return false;
    });
    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState({ [obj.state]: true });
      }
    });
  }
  //submenu click
  const submenuClick = (mainMenu) => {
    document.body.classList.remove("sidebar-icon-only");
    setState({
      isSubmenu: true,
      [mainMenu]: true,
    });
  };
  //mainMenu click
  const MainMenuClick = (title, url, e) => {
    document.body.classList.remove("sidebar-icon-only");
    toggleMenuState(title);
    setState({
      isSubmenu: false,
    });
    if (url) {
      e.preventDefault();
      const service = new AppInfoservice();
      service.getStoreUrl(title).then((res)=>{
        if(res.status){
          window.location.href = res.data.url;
        }
        else{

        }
      })
    }

  };


  const { t } = props;


  useEffect(() => {
    const items = props.sideMenu.Menu;
    setState({ menuitems: items }, () => hoverOpen());
    onRouteChanged();
  }, [props.sideMenu.Menu]);

  if (props.loaders) {
    return (
      <>
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            <li
              className={
                isPathActive(dashboard.to) ? "nav-item active" : "nav-item"
              }
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((items, index) => {
                return (
                  <div className="nav-link">
                    <Skeleton circle={true} height={40} width={40} />
                    <span style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                      <Skeleton width={100} count={1} />
                    </span>
                  </div>
                );
              })}
            </li>
          </ul>{" "}
        </nav>
      </>
    );
  }

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li
          className={
            isPathActive(dashboard.to) ? "nav-item active" : "nav-item"
          }
        >
          <MenuItemLink
            id="menu"
            icon={dashboard.icon}
            to={dashboard.to}
            title={dashboard.title}
            mainClick={MainMenuClick}

          />
        </li>

        {items &&
          items.map((item, index) => {
            const icon =
              item.icon === "SettingsInputAntennaIcon" ? (
                <SettingsInputAntennaIcon />
              ) : item.icon === "SettingsOutlinedIcon" ? (
                <SettingsOutlinedIcon />
              ) : item.icon === "GroupsOutlinedIcon" ? (
                <GroupsOutlinedIcon />
              ) : item.icon === "ShoppingBasketOutlinedIcon" ? (
                <ShoppingBasketOutlinedIcon />
              ) : item.icon === "CardGiftcardOutlinedIcon" ? (
                <CardGiftcardOutlinedIcon />
              ) : item.icon === "VolunteerActivismOutlinedIcon" ? (
                <VolunteerActivismOutlinedIcon />
              ): item.icon === "LiquorOutlinedIcon" ? (
                <LiquorOutlinedIcon />
              ) :(
               ''
              );

              
            return (
              <li
                className={`${
                  isPathActive(item.to) ? "nav-item active" : "nav-item"
                } ${state.isSubmenu && state[item.title] && "active"}`}
                key={index}
              >
                {item.subMenuList.length !== 0 ? (
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      className={
                        state[item.title]
                          ? "nav-link menu-expanded"
                          : "nav-link"
                      }
                      onClick={() => toggleMenuState(item.title)}
                      data-toggle="collapse"
                    >
                      <i className="menu-icon">{icon}</i>
                      {/* <i className={`${item.icon} menu-icon`}></i> */}
                      <span className="menu-title">
                        {t(`Sidemenu.${item.title}`)}
                      </span>
                      {state[item.title] ? (
                        <i
                          className={`fa fa-angle-down arrow-icon menu_sub_icon ${style.arrow_main_pg}`}
                        ></i>
                      ) : (
                        <i
                          className={`fa fa-angle-right arrow-icon menu_sub_icon ${style.arrow_main_pg}`}
                        ></i>
                      )}
                    </div>
                    <Collapse in={state[item.title]} className="side-menu">
                      <ul className="nav flex-column sub-menu sub_menu_bg">
                        {item.subMenuList.map((submenu, index) => {
                          return (
                            <li className="nav-item" key={index}>
                              <Link
                                className={
                                  isPathActive(submenu.to)
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to={submenu.to}
                                onClick={() => submenuClick(item.title)}
                              >
                                {t("Sidemenu." + submenu.title)}
                              </Link>
                            </li>
                          );
                        })}
                        {/* <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons">Buttons</Link></li>
                              <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns">Dropdowns</Link></li> */}
                      </ul>
                    </Collapse>
                  </>
                ) : (
                  <MenuItemLink
                    title={item.title}
                    to={item.to}
                    icon={item.icon}
                    mainClick={MainMenuClick}
                    url={item.url}
                    storeUrl={item.store_url}
                  />
                )}
              </li>
            );
          })}
        {/* <li className={ this.isPathActive(logout.to) ? 'nav-item active' : 'nav-item' } >
            <MenuItemLink 
              title={logout.title}
              to={logout.to}
              icon={logout.icon}
              mainClick={this.MainMenuClick}
              />
          </li> */}
      </ul>
    </nav>
  );
  function isPathActive(path) {
    return props.location.pathname.startsWith(path);
  }

  // componentDidMount() {
  //   const items = this.props.sideMenu.Menu;
  //   // const items = sidebaritems();
  //   this.setState({menuitems : items},() => this.hoverOpen())
  //   // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
  //   this.onRouteChanged();
  // }

  function hoverOpen() {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))
);
